// {6 8} {25 33}
export const symptomSearchData = [
  { id: "1", name: "Fainting" },
  { id: "2", name: "Fatigue" },
  { id: "3", name: "Feel No Air" },
  { id: "4", name: "Fearful" },
  { id: "5", name: "Febrile Seizure" },
  { id: "6", name: "Fever" },
  { id: "7", name: "Finger pain" },

  { id: "8", name: "Back Pain" },
  { id: "9", name: "Bad Health" },
  { id: "10", name: "Body Aches" },
  { id: "11", name: "Boil Symptoms" },
  { id: "12", name: "Bowel Habit Change" },
  { id: "13", name: "Breast Lumbs" },
  { id: "14", name: "Body Pain" },

  { id: "15", name: "Cough and Diarrhea" },
  { id: "16", name: "Cough and Difficulty talking" },
  { id: "17", name: "Cough and Dizziness" },
  { id: "18", name: "Cough and Dry eyes" },
  { id: "19", name: "Cough and Dry mouth" },
  { id: "20", name: "Cough and Excessive sweating" },
  { id: "21", name: "Cough and Eye Floaters" },
  { id: "22", name: "Cough and Fainting" },
  { id: "23", name: "Cough and Frequent urination" },
  { id: "24", name: "Cough and Fruity odor on breath" },
  { id: "25", name: "Cough with sputum" },

  {
    id: "26",
    name: "Change in stools, Pain, Painful bowel movements and Testicular pain",
  },
  {
    id: "27",
    name: "Change in stools, Protruding rectal material and Thin (pencil) stools",
  },
  { id: "28", name: "Change in vision and Eyes do not track together" },
  { id: "29", name: "Change in vision and Ringing in ears" },
  { id: "30", name: "Changes in Skin of the Breast" },
  { id: "31", name: "Chapped Lips (Cheilitis)" },
  { id: "32", name: "Charcot-Marie-Tooth Disease Symptoms and Signs" },

  { id: "33", name: "Chest Pain" },
  { id: "34", name: "Chest Tightness (Tightness in Chest)" },
  { id: "35", name: "Chickenpox Symptoms and Signs" },

  { id: "36", name: "Neck Stiffness" },
  { id: "37", name: "Night Sweats" },
];

export const dynamicData = [{ 3: {
    
} }];
