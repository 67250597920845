import React from "react";
import {
  AiOutlineCalendar,
  AiOutlineSetting,
  AiOutlineUnorderedList,
} from "react-icons/ai";

const Sidebar = () => {
  return (
    <>
      <section class="overflow-hidden min-h-full w-full">
        <div class="navbar-menu z-50 top-0 flex xl:flex flex-col justify-between bg-white my-2 p-2 w-full h-full overflow-y-auto">
          <div class="relative bg-white">
            <ul>
              <li>
                <a
                  class="p-3 py-4 flex items-center justify-between bg-blue-500 text-white hover:text-blue-500 hover:bg-gray-50 rounded-md"
                  href="#"
                >
                  <div class="flex items-center gap-2">
                    <AiOutlineUnorderedList size={24} />
                    <p class="text-coolGray-800 font-medium text-base">
                      Patient List
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="p-3 py-4 flex items-center justify-between text-coolGray-500 hover:text-blue-500 hover:bg-coolGray-50 rounded-md"
                  href="#"
                >
                  <div class="flex items-center gap-2">
                    <AiOutlineCalendar size={24} />
                    <p class="text-coolGray-800 font-medium text-base">
                      Calendar
                    </p>
                  </div>
                </a>
              </li>
              <li>
                <a
                  class="p-3 py-4 flex items-center justify-between text-coolGray-500 hover:text-blue-500 hover:bg-coolGray-50 rounded-md"
                  href="#"
                >
                  <div class="flex items-center gap-2">
                    <AiOutlineSetting size={24} />
                    <p class="text-coolGray-800 font-medium text-base">
                      Settings
                    </p>
                  </div>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </section>
    </>
  );
};

export default Sidebar;
