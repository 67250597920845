import React from "react";

const InfoTab = () => {
  return (
    <>
      <div className="flex flex-row gap-4">
        <div className="w-3/5 flex flex-col justify-between items-start gap-4">
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Patient Full Name</p>
              <p className="p-2 border-b border-gray-100 font-medium">
                Mr. Krishna Satyal
              </p>
            </div>
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Patient Id</p>
              <p className="p-2 border-b border-gray-100 font-medium">
                IN923535
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Patient Type</p>
              <p className="p-2 border-b border-gray-100 font-medium">
                In Patient
              </p>
            </div>
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Referred By</p>
              <p className="p-2 border-b border-gray-100 font-medium">
                Dr. Sona Rao
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Age/Sex</p>
              <p className="px-2 border-b border-gray-100 font-medium">
                52Y / Male
              </p>
            </div>
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">BMI</p>
              <p className="px-2 border-b border-gray-100 font-medium">28.0</p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Address</p>
              <p className="px-2 border-b border-gray-100 font-medium">
                35 Annapoorna layout, New sidhapudur, Coimbatore, TN - 641044
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Drug Allergies</p>
              <p className="px-2 border-b border-gray-100 font-medium">
                Penicillin
              </p>
            </div>
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Food Allergies</p>
              <p className="px-2 border-b border-gray-100 font-medium">
                Shrimp
              </p>
            </div>
          </div>
          <div className="w-full flex flex-row justify-between items-center gap-4">
            <div class="w-full block">
              <p class="mb-2 text-gray-600 leading-normal">Lifestyle Habits</p>
              <div className="flex flex-row gap-2 flex-wrap">
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <p class="inline-block py-1 px-3 text-sm leading-5 font-medium text-amber-800 bg-amber-200 hover:bg-opacity-100 bg-opacity-80 rounded-full">
                    Smoking
                  </p>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <p class="inline-block py-1 px-3 text-sm leading-5 font-medium text-amber-800 bg-amber-200 hover:bg-opacity-100 bg-opacity-80 rounded-full">
                    Drinking
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-2/5 bg-gray-50 border border-gray-100 rounded-xl shadow-md">
          <h4 class="text-lg mb-4 p-2 border-b bg-white">Today's vital</h4>
          <div className="flex flex-row justify-between items-center px-4 py-2">
            <p class="text-gray-500">Blood Pressure</p>
            <p class="text-red-500 font-medium">160/90</p>
          </div>
          <div className="flex flex-row justify-between items-center px-4 py-2">
            <p class="text-gray-500">Pulse Rate</p>
            <p class="text-amber-500 font-medium">110</p>
          </div>
          <div className="flex flex-row justify-between items-center px-4 py-2">
            <p class="text-gray-500">Body Tempurature</p>
            <p class="text-green-500 font-medium">98°C</p>
          </div>
          <div className="flex flex-row justify-between items-center px-4 py-2">
            <p class="text-gray-500">Respiration rate</p>
            <p class="text-green-500 font-medium">97%</p>
          </div>

          <h4 class="text-lg my-2 p-2 border-y bg-white">Comorbidities</h4>
          <div className="flex flex-col justify-between items-baseline px-4 py-2">
            <p class="text-gray-800 underline cursor-pointer">Diabetes</p>
            <span className="text-sm text-gray-400">(Since Sep-2015)</span>
          </div>
          <div className="flex flex-col justify-between items-start px-4 py-2">
            <p class="text-gray-800 underline cursor-pointer">
              Chronic Obstructive Pulmonary Disease
            </p>
            <span className="text-sm text-gray-400">(Since Jul-2018)</span>
          </div>
          <h4 class="text-lg my-2 p-2 border-y bg-white">Recent reports</h4>
          <div className="flex flex-col justify-between items-start px-4 py-2">
            <p class="text-gray-800 underline cursor-pointer">Chest X-Ray</p>
            <span className="text-sm text-gray-400">(Jul-2018)</span>
          </div>
          <div className="flex flex-col justify-between items-start px-4 py-2">
            <p class="text-gray-800 underline cursor-pointer">
              Arterial Blood Gas Analysis
              {/* Rapid Sputum Test */}
            </p>
            <span className="text-sm text-gray-400">(Sep-2022)</span>
          </div>
        </div>
      </div>
    </>
  );
};

export default InfoTab;
