import { Navigate, Route, Routes } from "react-router-dom";
import BaseLayout from "./component/Layout/BaseLayout";
import LoginPage from "./pages/Login";
import PatientDetailPage from "./pages/PatientDetailPage";
import PatientPage from "./pages/PatientList";

function App() {
  return (
    <>
      <Routes>
        <Route path="/patient" element={<BaseLayout />}>
          <Route path="/patient/list" element={<PatientPage />} />
          <Route path="/patient/detail-list" element={<PatientDetailPage />} />
        </Route>
        <Route>
          <Route path="/login" element={<LoginPage />} />
          {/* <Route path="/signup" element={<SignUp />} /> */}
        </Route>
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </>
  );
}

export default App;
