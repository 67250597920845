import React from "react";
import { Link } from "react-router-dom";
import Sidebar from "../../component/Sidebar";

const PatientPage = () => {
  return (
    <>
      <div className="flex flex-row justify-start items-start bg-white">
        <div className="w-1/6 h-full">
          <Sidebar />
        </div>
        <div className="w-5/6 px-8 border-l bg-gray-100">
          <div className="flex flex-col justify-start items-center h-screen">
            <div className="w-full max-w-screen-xl">
              <div class="w-full flex flex-wrap px-9 mb-4">
                <div class="w-full md:w-1/2 mb-6 md:mb-0 text-sm">
                  <h2 class="mt-8 text-3xl font-heading font-medium">
                    Patient List
                  </h2>
                </div>
              </div>
              <div class="relative inline-block w-full bg-white border border-gray-200 rounded-md overflow-hidden mt-4 mb-16">
                <table className="table-auto w-full border round-2">
                  <thead className="">
                    <tr className="text-xs text-left text-gray-500 bg-gray-50 font-bold">
                      <th className="px-2 py-4">PATIENT ID</th>
                      <th className="px-2 py-4">PATIENT NAME</th>
                      <th className="px-2 py-4">APPOINTMENT TIME</th>
                      <th className="px-2 py-4">LAST VISIT</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody className="">
                    <tr className="text-xs border-b-2 border-gray-100">
                      <td className="px-2 text-gray-600 font-medium py-4 ">
                        IN923535
                      </td>
                      <td className="px-2 font-medium">Mr. Krishna Satyal</td>
                      <td className="px-2 font-medium">10:30 AM</td>
                      <td className="px-2 font-medium">11.09.2022</td>
                      <td>
                        <Link
                          className="bg-blue-500 px-4 py-2 rounded text-white"
                          to={"/patient/detail-list"}
                        >
                          OPEN
                        </Link>
                      </td>
                    </tr>
                    <tr className="text-xs border-b-2 border-gray-100">
                      <td className="px-2 text-gray-600 font-medium py-4 ">
                        IN923535
                      </td>
                      <td className="px-2 font-medium">Mr. Manoj</td>
                      <td className="px-2 font-medium">01:00 AM</td>
                      <td className="px-2 font-medium">08.09.2022</td>
                      <td>
                        <Link
                          className="bg-blue-500 px-4 py-2 rounded text-white"
                          to={"/patient/detail-list"}
                        >
                          OPEN
                        </Link>
                      </td>
                    </tr>
                    <tr className="text-xs border-b-2 border-gray-100">
                      <td className="px-2 text-gray-600 font-medium py-4 ">
                        IN923535
                      </td>
                      <td className="px-2 font-medium">Mr. Kavin</td>
                      <td className="px-2 font-medium">04:00 AM</td>
                      <td className="px-2 font-medium">10.09.2022</td>
                      <td>
                        <Link
                          className="bg-blue-500 px-4 py-2 rounded text-white"
                          to={"/patient/detail-list"}
                        >
                          OPEN
                        </Link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PatientPage;
