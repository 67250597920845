import { Combobox, Tab, Transition } from "@headlessui/react";
import React, { Fragment, useState } from "react";
import { AiOutlineCheck } from "react-icons/ai";
import { IoAddCircleOutline, IoCloseCircleOutline } from "react-icons/io5";
import DiagnosticsTab from "./TabChildren/DiagnosticsTab";
import HistoryTab from "./TabChildren/HistoryTab";
import InfoTab from "./TabChildren/InfoTab";
import PathologyTab from "./TabChildren/PathologyTab";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const PatientDetailTabNavigation = ({
  selected,
  handleOnSelect,
  query,
  symptomsList,
  setQuery,
  filteredDisease,
}) => {
  let [categories] = useState({
    Information: <InfoTab />,
    History: <HistoryTab />,
    Pathology: <PathologyTab />,
    Diagnostics: <DiagnosticsTab />,
  });
  return (
    <>
      <Tab.Group>
        <Tab.List className="flex space-x-1 p-1">
          {Object.keys(categories).map((category) => (
            <Tab
              key={category}
              className={({ selected }) =>
                classNames(
                  "w-full py-2.5 text-sm font-medium leading-5 text-blue-700",
                  selected
                    ? "bg-white border-b-2 border-b-blue-500"
                    : "text-gray-500 border-b-2 border-gray-100 hover:border-b-gray-300 transition-all duration-300"
                )
              }
            >
              {category}
            </Tab>
          ))}
        </Tab.List>
        <Tab.Panels className="mt-2">
          {Object.values(categories).map((posts, idx) => (
            <Tab.Panel
              key={idx}
              className={"bg-white p-2 outline-none focus:ring-0"}
            >
              {posts}
            </Tab.Panel>
          ))}
        </Tab.Panels>
      </Tab.Group>
    </>
  );
};

export default PatientDetailTabNavigation;
