import { Menu, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { FiChevronDown } from "react-icons/fi";
import { Link, useLocation } from "react-router-dom";

const Navbar = () => {
  const location = useLocation();
  return (
    <>
      <section class="border-b">
        <div class="relative px-6 lg:px-12 bg-gray-50">
          <nav class="flex py-8 xl:py-4 justify-between">
            <ul class="hidden xl:flex flex-wrap items-center mr-auto">
              <li class="mr-6">
                <Link
                  class="flex items-center text-sm font-medium text-gray-400 hover:text-gray-500"
                  to="/patient/list"
                >
                  <span>Home</span>
                  <svg
                    class="ml-6"
                    width="4"
                    height="7"
                    viewbox="0 0 4 7"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M0.150291 0.898704C-0.0500975 0.692525 -0.0500975 0.359292 0.150291 0.154634C0.35068 -0.0507836 0.674443 -0.0523053 0.874831 0.154634L3.7386 3.12787C3.93899 3.33329 3.93899 3.66576 3.7386 3.8727L0.874832 6.84594C0.675191 7.05135 0.35068 7.05135 0.150292 6.84594C-0.0500972 6.63976 -0.0500972 6.30652 0.150292 6.10187L2.49888 3.49914L0.150291 0.898704Z"
                      fill="currentColor"
                    ></path>
                  </svg>
                </Link>
              </li>
              {location.pathname.includes("/patient/detail-list") ? (
                <li>
                  <Link
                    class="text-sm font-medium text-blue-500 hover:text-blue-600"
                    to="/patient/detail-list"
                  >
                    Patient detail
                  </Link>
                </li>
              ) : (
                <></>
              )}
            </ul>
            <div class="hidden xl:flex items-center justify-between">
              <Menu as="div" className="relative inline-block text-left">
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md bg-transparent px-4 py-2 text-sm font-medium text-gray-900">
                    <span class="flex flex-row justify-center gap-4 items-center font-heading font-medium leading-tight">
                      Dr. Sona Rao
                      <FiChevronDown size={24} />
                    </span>
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 mt-2 w-36 origin-top-right divide-y divide-gray-100 rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            className={`${
                              active
                                ? "bg-blue-500 text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            Logout
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
          </nav>
        </div>
      </section>
    </>
  );
};

export default Navbar;
