import React from "react";

import {
  IoMdTime,
  IoMdRadioButtonOff,
  IoMdRadioButtonOn,
} from "react-icons/io";

const HistoryTab = () => {
  return (
    <>
      <div className="flex flex-row">
        <div class="flex items-center mb-9 gap-2 overflow-hidden w-3/4">
          {/* <div class="w-auto pr-8">
          <img
          src="https://shuffle.dev/uinel-assets/elements/dashboard-tables/timeline.svg"
          alt=""
          />
        </div> */}
          <div class="w-full flex-wrap">
            <div className="flex flex-row justify-start items-center gap-16 mb-9 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOff size={24} />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  Investigate COPD
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>05-Mar-2022</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Medical Condition
                  </a>
                </div>
                <p className="text-xs text-gray-600">
                  Note: COPD complication has been stable with proper medication
                </p>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center gap-16 mb-9 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOn size={24} className="text-green-600" />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  Vaccinated for Covid-19
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>19-Feb-2022</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Vaccinated
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center gap-16 mb-9 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOn size={24} className="text-green-600" />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  Diagnosed for Chronic Obstructive Pulmonary Disease
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>14-Jul-2018</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Vaccinated
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center gap-16 mb-9 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOn size={24} className="text-green-600" />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  K-wire left arm surgery
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>02-Nov-2017</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Past Procedures
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center gap-16 mb-9 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOn size={24} className="text-green-600" />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  Diagnosed for type-1 diabetes
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>24-Dec-2015</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Medical Condition
                  </a>
                </div>
              </div>
            </div>
            <div className="flex flex-row justify-start items-center gap-16 px-4 py-2 border-2 border-gray-100 rounded">
              <IoMdRadioButtonOn size={24} className="text-green-600" />
              <div>
                <h3 class="mb-1 text-lg font-heading font-medium">
                  Vaccinated for hepatitis A
                </h3>
                <div class="flex items-center text-gray-500">
                  <IoMdTime size={18} />
                  <span>20-Mar-2012</span>
                </div>
                <div class="flex items-center pt-1 pb-2 border-b border-gray-100">
                  <a
                    class="inline-block py-1 px-3 text-sm leading-5 font-medium text-blue-500 bg-blue-200 hover:bg-opacity-100 bg-opacity-80 rounded-full"
                    href="#"
                  >
                    Medical Condition
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-1/4 p-2">
          <div className="w-full flex flex-col gap-2 justify-start items-start bg-red-100 border border-gray-100 shadow-md rounded-md">
            <h4 class="w-full text-lg p-2 text-gray-600 border-b bg-white">
              Critical Medical History
            </h4>
            <div className="w-full mx-4 my-2 flex flex-col gap-2 justify-start items-start">
              <p class="text-gray-800 font-medium">
                1. Chronic Obstructive Pulmonary Disease
              </p>
              <p class="text-gray-800 font-medium">2. Type-1 diabetes</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HistoryTab;
