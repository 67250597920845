import React, { Fragment, useState } from "react";
import PatientDetailTabNavigation from "../../component/TabLayout/PatientDetail/PatientDetailTabNavigation";
import PatientPossibilityTable from "../../component/Table/PatientPossibilityTable";

import { IoCloseCircleOutline, IoAddCircleOutline } from "react-icons/io5";
import { Combobox, Transition } from "@headlessui/react";

import {
  AiOutlineCheck,
  AiOutlineWarning,
  AiOutlineEdit,
} from "react-icons/ai";
import { symptomSearchData } from "./config";

const PatientDetailPage = () => {
  const [selected, setSelected] = useState("");
  const [query, setQuery] = useState("");

  const [searchSymptom, setSearchSymptom] = useState("");
  const [symptomsList, setSymptomsList] = useState([]);

  const handleOnSelect = (value) => {
    console.log(value);
    setSymptomsList((oldArr) => [...oldArr, value]);
    setSelected("");
  };

  const filteredDisease =
    query === ""
      ? symptomSearchData
      : symptomSearchData.filter((person) =>
          person.name
            .toLowerCase()
            .replace(/\s+/g, "")
            .includes(query.toLowerCase().replace(/\s+/g, ""))
        );

  return (
    <>
      <section class="py-4 bg-gray-100 min-h-screen">
        <div class="max-w-screen-2xl container mx-auto">
          <div class="w-full flex flex-wrap">
            <div class="w-full lg:w-2/3 p-4">
              <div class="p-7 pt-12 pb-12 bg-white rounded-5xl">
                <div class="flex flex-wrap px-9 mb-4">
                  <div class="w-full md:w-1/2 mb-6 md:mb-0 text-sm">
                    <h2 class="mb-2 text-3xl font-heading font-medium">
                      Mr. Krishna Satyal
                      <span className="text-gray-500 text-sm">(IN789653)</span>
                    </h2>
                    <p class="text-gray-400 font-heading">
                      Age/Sex: <span className="text-gray-900">52Y / Male</span>
                    </p>
                    <p class="text-gray-400 font-heading">
                      BMI: <span className="text-gray-900">28.0</span>
                    </p>
                  </div>
                </div>

                <div class="w-full bg-white">
                  <div class="px-8">
                    <div className="flex flex-col justify-between items-start">
                      <p className="mb-4 text-gray-500">Key Symptoms:</p>

                      <div class="flex items-center justify-center">
                        <Combobox value={selected} onChange={handleOnSelect}>
                          <div className="relative">
                            <div className="relative w-full border border-gray-300 cursor-default overflow-hidden rounded-md bg-white text-left focus:outline-none sm:text-sm">
                              <Combobox.Input
                                className="w-96 border-none py-2 px-4 text-sm leading-5 text-gray-900 focus:ring-0"
                                displayValue={(person) => person.name}
                                onChange={(event) =>
                                  setQuery(event.target.value)
                                }
                              />
                              <Combobox.Button className="absolute inset-y-0 right-0 flex items-center pr-2">
                                <IoAddCircleOutline
                                  className="h-4 w-4 text-gray-400"
                                  aria-hidden="true"
                                />
                              </Combobox.Button>
                            </div>
                            <Transition
                              as={Fragment}
                              leave="transition ease-in duration-100"
                              leaveFrom="opacity-100"
                              leaveTo="opacity-0"
                              afterLeave={() => setQuery("")}
                            >
                              <Combobox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {filteredDisease?.length === 0 &&
                                query !== "" ? (
                                  <div className="relative cursor-default select-none py-2 px-4 text-gray-700">
                                    Nothing found.
                                  </div>
                                ) : (
                                  filteredDisease?.map((person) => (
                                    <Combobox.Option
                                      key={person.id}
                                      className={({ active }) =>
                                        `relative cursor-default select-none py-2 pl-10 pr-4 ${
                                          active
                                            ? "bg-blue-600 text-white"
                                            : "text-gray-900"
                                        }`
                                      }
                                      value={person}
                                    >
                                      {({ selected, active }) => (
                                        <>
                                          <span
                                            className={`block truncate ${
                                              selected
                                                ? "font-medium"
                                                : "font-normal"
                                            }`}
                                          >
                                            {person.name}
                                          </span>
                                          {selected ? (
                                            <span
                                              className={`absolute inset-y-0 left-0 flex items-center pl-3 ${
                                                active
                                                  ? "text-white"
                                                  : "text-blue-600"
                                              }`}
                                            >
                                              <AiOutlineCheck
                                                className="h-5 w-5"
                                                aria-hidden="true"
                                              />
                                            </span>
                                          ) : null}
                                        </>
                                      )}
                                    </Combobox.Option>
                                  ))
                                )}
                              </Combobox.Options>
                            </Transition>
                          </div>
                        </Combobox>
                      </div>
                    </div>
                    <div class="flex flex-items pt-4">
                      <div class="flex flex-row flex-wrap mr-auto">
                        {symptomsList?.map((val) => (
                          <div
                            key={val.id}
                            className="flex flex-row justify-center items-center gap-2 py-2 px-4 m-1 border-2 border-gray-200 hover:border-gray-400 rounded-full"
                          >
                            <p class="inline-block text-gray-800 font-medium">
                              {val.name}
                            </p>
                            <IoCloseCircleOutline
                              size={24}
                              className="text-gray-400"
                            />
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                  <div class="px-8 mb-8 flex flex-row justify-start items-center border-b border-gray-200">
                    <p className="text-start text-gray-500">
                      Write notes about the patient:
                    </p>
                    <button className="flex flex-row items-center m-4 px-4 py-2 text-sm text-blue-500 bg-gray-100 rounded-md">
                      <IoAddCircleOutline size={16} />
                      &nbsp;Add Notes
                    </button>
                    {/* <textarea
              class="form-control block w-full px-3 py-2 text-base font-normal text-gray-700 bg-white bg-clip-padding border border-solid border-gray-300 rounded-lg transition ease-in-out m-0 focus:bg-white focus:outline-none"
              id="textarea"
              rows="5"
              placeholder="Enter notes about the patient"
            ></textarea>
            <button className="m-4 px-4 py-2 bg-blue-500 text-white rounded-md">
              Submit
            </button> */}
                  </div>
                </div>

                <PatientDetailTabNavigation
                  selected={selected}
                  query={query}
                  setQuery={setQuery}
                  handleOnSelect={handleOnSelect}
                  filteredDisease={filteredDisease}
                  symptomsList={symptomsList}
                />
              </div>
            </div>
            <div class="w-full lg:w-1/3 p-4">
              <div class="p-8 bg-white">
                <h3 class="mb-4 text-3xl font-heading font-medium">
                  Possibile Causes
                </h3>
                <PatientPossibilityTable count={symptomsList?.length} />
              </div>
              <div className="bg-white p-8 flex flex-col justify-end">
                <h3 class="mb-4 text-3xl font-medium">
                  Recommended Treatment Plan
                </h3>
                <div className="bg-amber-100 p-2 rounded-md border border-amber-300">
                  <p>1. COPD results is negative from chest xray reports</p>
                  <p>2. Chest xray report suggest chances of pneumonia</p>
                  <p>
                    3. Coimbatore region has a recent surge in 10% increase of
                    Tuberculosis cases
                  </p>
                  <span className="w-full text-amber-500 flex justify-end px-2">
                    <AiOutlineWarning />
                  </span>
                </div>
                <p className="text-sm text-gray-500 my-2 border-b">Test:</p>
                {symptomsList?.length === 3 ? (
                  <ul>
                    <li className="text-lg font-medium">Rapid Sputum Tests</li>
                  </ul>
                ) : symptomsList?.length === 5 ? (
                  <ul>
                    <li className="text-lg font-medium">TB Skin Test</li>
                    <li className="text-lg font-medium">Rapid Sputum Tests</li>
                  </ul>
                ) : (
                  <></>
                )}
                <p className="text-sm text-gray-500 my-2 border-b">Medicine:</p>
                {symptomsList?.length === 3 ? (
                  <ul>
                    <li className="text-lg font-medium">Levaquin</li>
                    <li className="text-lg font-medium">Ibuprofen</li>
                    <li className="text-lg font-medium">Relenza</li>
                  </ul>
                ) : symptomsList?.length === 5 ? (
                  <ul>
                    <li className="text-lg font-medium">Isoniozid</li>
                    <li className="text-lg font-medium">Rifampicin</li>
                    <li className="text-lg font-medium">Pyrazinamide</li>
                    <li className="text-lg font-medium">Ethambutol</li>
                  </ul>
                ) : (
                  <></>
                )}

                <button className="flex flex-row justify-center items-center mt-4 gap-2 text-xs p-2 text-blue-500 bg-blue-50 rounded-md">
                  <AiOutlineEdit size={16} />
                  Write Prescription
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PatientDetailPage;
