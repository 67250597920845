import React from "react";

const PatientPossibilityTable = ({ count }) => {
  return (
    <>
      <table class="table-auto w-full">
        <thead>
          <tr>
            <th class="text-start">
              {/* <input class="w-6 h-6 bg-white" type="checkbox" /> */}
            </th>
            <th class="text-left items-center py-4">
              {/* <span class="text-sm text-gray-500 font-heading font-semibold uppercase">
                Causes
              </span> */}
            </th>
          </tr>
        </thead>
        {count === 3 ? (
          <tbody>
            <tr className="bg-gray-100 rounded-lg">
              <td className="pl-4">
                <input
                  class="w-6 h-6 bg-white"
                  type="checkbox"
                  defaultChecked
                />
              </td>
              <td class="p-0">
                <div class="flex items-center w-full pr-12 py-2 min-w-max">
                  <div class="w-full flex flex-col justify-center items-start">
                    <span class="block mb-2 text-gray-800 font-medium">
                      Pneumonia
                    </span>
                    <div class="relative w-full h-1 bg-indigo-100 rounded-sm">
                      <div class="absolute top-0 left-0 w-5/6 h-full bg-blue-500 rounded-sm"></div>
                    </div>
                    <span class="block text-sm text-gray-400 font-heading">
                      85%
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100 rounded-lg">
              <td className="pl-4">
                <input class="w-6 h-6 bg-white" type="checkbox" />
              </td>
              <td class="p-0">
                <div class="flex items-center w-full pr-12 py-2 min-w-max">
                  <div class="w-full flex flex-col justify-center items-start">
                    <span class="block mb-2 text-gray-800 font-medium">
                      Viral Fever
                    </span>
                    <div class="relative w-full h-1 bg-indigo-100 rounded-sm">
                      <div class="absolute top-0 left-0 w-1/4 h-full bg-blue-500 rounded-sm"></div>
                    </div>
                    <span class="block text-sm text-gray-400 font-heading">
                      23%
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100 rounded-lg">
              <td className="pl-4">
                <input class="w-6 h-6 bg-white" type="checkbox" />
              </td>
              <td class="p-0">
                <div class="flex items-center w-full pr-12 py-2 min-w-max">
                  <div class="w-full flex flex-col justify-center items-start">
                    <span class="block mb-2 text-gray-800 font-medium">
                      Tuberculosis
                    </span>
                    <div class="relative w-full h-1 bg-indigo-100 rounded-sm">
                      <div class="absolute top-0 left-0 w-2/5 h-full bg-blue-500 rounded-sm"></div>
                    </div>
                    <span class="block text-sm text-gray-400 font-heading">
                      40%
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : count === 5 ? (
          <tbody>
            <tr className="bg-gray-100 rounded-lg">
              <td className="pl-4">
                <input
                  class="w-6 h-6 bg-white"
                  type="checkbox"
                  defaultChecked
                />
              </td>
              <td class="p-0">
                <div class="flex items-center w-full pr-12 py-2 min-w-max">
                  <div class="w-full flex flex-col justify-center items-start">
                    <span class="block mb-2 text-gray-800 font-medium">
                      Tuberculosis
                    </span>
                    <div class="relative w-full h-1 bg-indigo-100 rounded-sm">
                      <div class="absolute top-0 left-0 w-11/12 h-full bg-blue-500 rounded-sm"></div>
                    </div>
                    <span class="block text-sm text-gray-400 font-heading">
                      90%
                    </span>
                  </div>
                </div>
              </td>
            </tr>
            <tr className="bg-gray-100 rounded-lg">
              <td className="pl-4">
                <input class="w-6 h-6 bg-white" type="checkbox" />
              </td>
              <td class="p-0">
                <div class="flex items-center w-full pr-12 py-2 min-w-max">
                  <div class="w-full flex flex-col justify-center items-start">
                    <span class="block mb-2 text-gray-800 font-medium">
                      Pneumonia
                    </span>
                    <div class="relative w-full h-1 bg-indigo-100 rounded-sm">
                      <div class="absolute top-0 left-0 w-7/12 h-full bg-blue-500 rounded-sm"></div>
                    </div>
                    <span class="block text-sm text-gray-400 font-heading">
                      55%
                    </span>
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        ) : (
          <></>
        )}
      </table>
    </>
  );
};

export default PatientPossibilityTable;
