import React from "react";
import { Link } from "react-router-dom";

const LoginPage = () => {
  return (
    <>
      <section className="relative md:p-8 h-screen flex justify-center items-center md:overflow-hidden bg-gray-200">
        <img
          className="absolute top-0 left-0"
          src="https://shuffle.dev/uinel-assets/images/sign-up/color-circles2.png"
          alt=""
        />
        <img
          className="absolute bottom-0 right-0"
          src="https://shuffle.dev/uinel-assets/images/sign-up/half-circle-purple.png"
          alt=""
        />
        <div className="relative w-full py-20 overflow-y-auto bg-white rounded-3xl">
          <p className="w-full text-center pt-2 pb-16 text-4xl font-bold">
            Cubebase
          </p>
          <div className="container px-4 mx-auto">
            <div className="flex flex-wrap -mx-4 px-8">
              <div className="w-full lg:w-1/2 px-8 lg:mb-0 flex justify-center items-center">
                <div className="lg:max-w-md">
                  <h2 className="h-full text-8xl leading-tight font-heading font-bold">
                    Welcome Back
                  </h2>
                  {/* <p className="text-lg text-gray-400">
                    "Observation, reason, human understanding, courage these
                    make the physician."
                    <br /> - Martin H. Fischer
                  </p> */}
                </div>
              </div>
              <div className="w-full lg:w-1/2 p-4 bg-gray-100 rounded-3xl">
                <div className="my-8 max-w-md mx-auto">
                  <h3 className="mb-8 text-xl text-gray-400">
                    Login with Email
                  </h3>
                  <input
                    className="w-full mb-6 py-5 px-12 text-xl border-2 border-blue-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    type="email"
                    placeholder="Your Email"
                  />
                  <input
                    className="w-full mb-6 py-5 px-12 text-xl border-2 border-blue-400 rounded-xl focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    type="password"
                    placeholder="Password"
                  />
                  <Link
                    className="block py-5 px-10 mx-auto w-full md:max-w-max text-xl leading-6 text-white font-medium tracking-tighter font-heading text-center bg-blue-500 hover:bg-blue-600 focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 rounded-xl"
                    to="/patient/list"
                  >
                    Login
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default LoginPage;
