import React from "react";

const DiagnosticsTab = () => {
  return (
    <>
      <div class="relative inline-block min-w-full border border-gray-200 rounded-md overflow-hidden">
        <table className="table-auto w-full border round-2">
          <thead className="">
            <tr className="text-xs text-left text-gray-500 bg-gray-50 font-bold">
              <th className="px-2 py-4">DATE</th>
              <th className="px-2 py-4">TEST</th>
              <th className="px-2 py-4">REFERRED BY</th>
              <th className="px-2 py-4">LINK</th>
            </tr>
          </thead>
          <tbody className="">
            <tr className="text-xs border-b-2 border-gray-100 hover:bg-gray-200  cursor-pointer">
              <td className="px-2 text-gray-600 font-medium py-4 ">
                11.09.2022
              </td>
              <td className="px-2 font-medium">Chest XRAY</td>
              <td className="px-2 font-medium">Dr. Shailendra Trivedi</td>
              <td className="px-2 text-[0.7rem] text-gray-600">
                <span class="inline-block py-1 px-2 text-white bg-blue-500 rounded-full">
                  View report
                </span>
              </td>
            </tr>
            <tr className="text-xs border-b-2 border-gray-100 hover:bg-gray-200 cursor-pointer   ">
              <td className="px-2 font-medium py-4">03.06.2020</td>
              <td className="px-2 font-medium">T2 weighted MRI</td>
              <td className="px-2 font-medium">Dr. Santosh Kondekar</td>
              <td className="px-2 text-[0.7rem] text-gray-600">
                <span class="inline-block py-1 px-2 text-white bg-blue-500 rounded-full">
                  View report
                </span>
              </td>
            </tr>

            <tr className="text-xs hover:bg-gray-200 cursor-pointer">
              <td className="px-2 font-medium py-4">02.11.2017</td>
              <td className="px-2 font-medium">Right arm XRAY</td>
              <td className="px-2 font-medium">Dr. Udaya Nath Sahoo</td>{" "}
              <td className="px-2 text-[0.7rem] text-gray-600">
                <span class="inline-block py-1 px-2 text-white bg-blue-500 rounded-full">
                  View report
                </span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default DiagnosticsTab;
